<template>
  <navbar :is-blur="true" :dark-mode="true" />

  <div class="container mt-8">
    <div class="row mb-3">
      <div class="col">
        <span v-if="game && game[0]"><router-link to="/" class="text-white">{{ $t('gameSingle.index')}}</router-link> / <router-link to="/game-topup" class="text-white">{{ $t('gameSingle.topup')}}</router-link> / {{ game[0]?.name }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">

        <section class="sticky-top" v-if="game && game[0]">
          <div class="container px-0 shadow-blur rounded mb-4">
            <div class="row" v-if="game && game[0]">
              <div class="col-md-4 p-3 col-3 z-index-1 d-flex justify-content-center align-items-center text-center">
                <img class="w-100 rounded ms-4" :src="game[0].icon_url" :alt="`GoldenBug รับเติมเกม ${ game[0].name }`">
              </div>
              <div class="col-md-8 col-9 d-flex align-items-start z-index-1">
                <div class="p-2">
                  <h1 class="text-lg text-white">{{ game[0].name }}</h1>
                  <div id="game-detail" class="game-detail text-white" :class="{'active': isActive}">
                    <p class="m-0 p-0">{{ game[0].description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-12 comment-con" v-if="game && game[0].compare_prices_img_url">
              <div class="card bg-transparent mb-4">
                <div style="height: 400px;">
                  <img class="image-fluid w-100 h-100" style="object-fit: contain;" :src="game[0].compare_prices_img_url" alt="">
                </div>
              </div>
              <div class="card bg-white shadow-lg rounded-lg overflow-hidden mb-4" :class="isMobile ? 'd-none' : '' ">
                <div class="card-header bg-primary text-white py-3">
                  <h4 class="mb-0 font-weight-bold text-white">Review</h4>
                </div>
                <div class="card-body p-4">
                  <div class="fb-comments" 
                  data-href="https://termgame.goldenbug.co/" 
                  data-width="100%" 
                       data-numposts="5"
                       data-order-by="reverse_time">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="col-lg-6">
        <div class="">
          <div class="row d-flex justify-content-start px-3 mb-3 align-items-center gap-3">
            <h2 class="text-danger px-0 mb-0 w-auto">{{ $t('gameSingle.writeForm')}} <span class="text-danger">*</span>
            </h2>
          </div>
          <div v-if="game && game[0].game_form_field_arr" class="row blur-dark mx-sm-0 mx-0 position-relative z-index-1">
            <div v-for="(f,index) in game[0].game_form_field_arr" :key="index" class="col-12 col-md-6 col-lg-6 col-xl-6 mt-lg-n2 mt-4 mb-2 px-0">

              <div class="me-3 mb-3" v-if="f.type != 'server' && f.type != 'game_server' && !this.game[0].pattern">
                <label for="">{{f.label ? f.label: f.name}}
                  <span class="text-danger" v-if="f.require">*</span>
                  <button
                    v-if="f?.name.toLowerCase() == 'uid'"
                    class="mt-0 mb-0 btn border-none text-danger text-sm py-0"
                    @click="showModal('uid')">
                    <i class="fas fa-info-circle"></i> <u>{{ game && game[0]?.information_remark || 'วิธีดู UID' }}</u>
                  </button>
                </label>
                <input type="{{ f.type }}" v-model="order_printout[f.name]" 
                :ref="`${f.type}_${index}`" :data-error-display="f.name"
                :placeholder="f?.placeholder ? f?.placeholder : f.name" class="form-control" :required="f.require" @input="updateOrderField(f.name, $event.target.value)">
              </div>
              
                <div class="me-3 mb-3" v-if="f.type != 'server' && f.type != 'game_server' && this.game[0].pattern">
                  <label for="">{{f.label ? f.label: f.name}}
                    <span class="text-danger" v-if="f.require">*</span>
                    <button
                      v-if="f?.name.toLowerCase() == 'uid'"
                      class="mt-0 mb-0 btn border-none text-danger text-sm py-0"
                      @click="showModal('uid')">
                      <i class="fas fa-info-circle"></i> <u>{{ game && game[0]?.information_remark || 'วิธีดู UID' }}</u>
                    </button>
                  </label>
                  <div class="d-flex justify-content-center align-items-center">
                    <input type="{{ f.type }}" 
                           v-model="order_printout[f.name]" 
                           :ref="`${f.type}_${index}`" 
                           :data-error-display="f.name"
                           :placeholder="f?.placeholder ? f?.placeholder : f.name" 
                           class="form-control" 
                           :class="{ 
                            'valid-input': formCurrect == true , 
                            'is-invalid': formErrors[f.name] 
                          }"
                           :required="f.require" 
                           @input="validateInput(f.name, $event.target.value)">
                    <i v-if="formCurrect == true" class="fas fa-check valid-icon "></i>
                  </div>
                  <!-- แสดงข้อความข้อผิดพลาด -->
                  <div v-if="formErrors[f.name]" class="text-danger">
                    {{ formErrors[f.name] }}
                  </div>
                </div>
                
                <div v-if="f.type == 'game_server'">
                  <label for="">{{ $t('gameSingle.chooseServer') }} <span class="text-danger" v-if="f.require">*</span></label>
                  <div class="input-group">
                    <span class="input-group-text"><i class="fas fa-box"></i></span>
                    <select class="form-select" v-model="order_printout['game_server']" :ref="`${f.type}_${index}`" :data-error-display="f.name" @change="updateServerName">
                      <option v-for="(server, i_serv) in game[0].gameservers" :key="i_serv" :value="server.value">{{ server.name }}</option>
                    </select>
                  </div>
                  <p v-if="f.require && formErrors['server']" class="mb-1" style="color: red;">{{ formErrors[f.name] }}</p>
                </div>

                <p v-if="f.require && formErrors[`${f.type}_${index}`]" class="mb-1" style="color: red;">{{ formErrors[`${f.type}_${index}`].replace(`${f.type}_${index}`, f.name) }}</p>
            </div>              
          </div>
          <div class="j-space my-3"></div>

          <div class="row justify-content-center" v-if="game && game[0].topup_auto">
            <div class="col-12 d-flex justify-content-between">
              <h2 class="text-danger mb-md-4">{{ $t('gameSingle.choosePackeage') }}</h2>
            </div>
            <div class="col-12 mb-3">
              <div class="row" v-if="products && products.length">
                <div class="col-sm-12 col-md-6 px-2 position-relative"  v-for="(item, index) in sortedProducts" :key="index">
                  <div class="card card-game d-flex flex-column position-relative cursor-pointer -item blur-dark" v-if="item.status_active" :class="{'item-active' : checkCart(item) }" @click.stop="addItem(index, item)" style="height: 280px;">
                    <h6 class="position-absolute m-0 bottom-0 end-0"><span class="badge bg-danger rounded-gb text-white" v-if="item.post_status_text == '1'">{{ $t('gameSingle.seller') }}</span></h6>
                    <h6 class="position-absolute m-0 bottom-0 end-0"><span class="badge bg-danger rounded-gb text-white" v-if="item.post_status_text == '2'">{{ $t('gameSingle.promotion') }}</span></h6>
                    <h6 class="position-absolute m-0 bottom-0 end-0"><span class="badge bg-danger rounded-gb text-white" v-if="item.post_status_text == '3'">{{ $t('gameSingle.hot') }}</span></h6>
                    <i v-if="checkCart(item)" @click.stop.prevent="removeItem(item)" title="ลบ" class="cursor-pointer position-absolute top-2 end-2 far fa-check-circle fa-2x"></i>
                    <div class="game-image m-auto mt-5">
                      <img v-if="item.img_url && item.img_url !== '/'" class="img-fluid m-auto" :src="`${item.img_url}`" alt="" style="object-fit: cover; height: 60px; width: 60px;">                  
                       <!-- <img v-else class="mx-auto" width="60" src="@/assets/img/items/item-genshin.png" alt="" style="object-fit: cover; height: 60px; width: 60px;"> -->
                      <img v-else class="img-fluid m-auto" src="https://via.placeholder.com/150" alt="" style="object-fit: cover; height: 60px; width: 60px;">
                    </div>
                    <div class="card-body d-flex flex-column flex-grow-1 p-0">
                      <div class="product-title">
                        <div class="d-flex justify-content-center align-items-center" style="height: 120px;">
                          <h3 class="text-desc p-0 mt-0">{{ item.name }}</h3>
                        </div>
                        <p class="m-0 text-center">{{ item.description }}</p>
                        <h4 v-if="item.cost == item.price" class="title text-lg price-tag d-flex justify-content-center gap-2 badge px-1 py-1"  style="font-size: 1rem !important;"><i class="fas fa-coins"></i>{{ this.getMemberProfile && marginTypeInPage > 0 ? this.$filters.toCurrency(item.price * (1 - marginTypeInPage / 100)) : this.$filters.toCurrency(item.price) }}</h4>
                        <div v-if="item.cost != item.price" class="title price-tag text-center badge pt-0 d-md-flex">
                          <span :class="{'discount': !checkCart(item), 'discount-hover': checkCart(item)}" class="discount-mobile me-1 mb-1" style="text-decoration: line-through;"><i class="fas fa-coins"></i> {{ this.$filters.toCurrency(item.cost) }}</span>
                          <span :class="{'text-danger': !checkCart(item), 'text-white': checkCart(item)}" class="price price-mobile">{{ this.getMemberProfile && marginTypeInPage > 0 ? this.$filters.toCurrency(item.price * (1 - marginTypeInPage / 100)) : this.$filters.toCurrency(item.price) }}</span> 
                        </div>
                        <div class="position-absolute m-0 top-0 start-0" v-if="item.cost != item.price"><span class="badge savings" style="font-size: 10px;">Save {{ this.getMemberProfile && marginTypeInPage > 0 ? (((item.cost - item.price) / item.cost * 100)+ marginTypeInPage).toFixed(2) : ((item.cost - item.price) / item.cost * 100).toFixed(2) }}%</span></div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="card card-game d-flex flex-column position-relative cursor-pointer -item blur-dark" style="height: 280px;">
                    <h6 class="position-absolute m-0 bottom-0 end-0"><span class="badge bg-danger rounded-gb text-white" v-if="item.post_status_text == '1'">{{ $t('gameSingle.seller') }}</span></h6>
                    <h6 class="position-absolute m-0 bottom-0 end-0"><span class="badge bg-danger rounded-gb text-white" v-if="item.post_status_text == '2'">{{ $t('gameSingle.promotion') }}</span></h6>
                    <h6 class="position-absolute m-0 bottom-0 end-0"><span class="badge bg-danger rounded-gb text-white" v-if="item.post_status_text == '3'">{{ $t('gameSingle.hot') }}</span></h6>
                    <i v-if="checkCart(item)" @click.stop.prevent="removeItem(item)" title="ลบ" class="cursor-pointer position-absolute top-2 end-2 far fa-check-circle fa-2x"></i>
                    <!-- <img v-if="item.img_url && item.img_url !== '/'" class="mx-auto" width="60" :src="`${item.img_url}`" alt="" style="height: 60px; width: 60px;"> -->
                    <div class="game-image m-auto mt-5">
                      <img v-if="item.img_url && item.img_url !== '/'" class="img-fluid m-auto greyscale" :src="`${item.img_url}`" alt="image greyscale" style="object-fit: cover; height: 60px; width: 60px;">
                    </div>
                    <!-- <img v-else class="mx-auto" width="60" src="@/assets/img/items/item-genshin.png" alt="" style="height: 60px; width: 60px;"> -->
                    <div class="card-body d-flex flex-column flex-grow-1 p-0">
                      <div class="product-title">
                        <div class="d-flex justify-content-center align-items-center" style="height: 120px;">
                          <h3 class="text-desc p-0 mt-0" style="font-size: 1rem;">{{ item.name }}</h3>
                        </div>
                        <p class="m-0 text-center">{{ item.description }}</p>
                        <p class="m-0 text-center fw-bold mb-2">Sold out</p>
                        <div class="position-absolute m-0 top-0 start-0" v-if="item.cost != item.price"><span class="badge savings" style="font-size: 10px;">Save {{ this.getMemberProfile && marginTypeInPage > 0 ? (((item.cost - item.price) / item.cost * 100)+ marginTypeInPage).toFixed(2) : ((item.cost - item.price) / item.cost * 100).toFixed(2) }}%</span></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12" v-if="getSelectedItem && !getSelectedItem.length && formErrors['product_list']">
                  <p class="mb-1" style="color: red;">{{ formErrors['product_list'] }}</p>
                </div>
              </div>
               <div v-else class="row">
                <div class="col py-5 text-center">
                  <i class="fas fa-smile fa-2x"></i>
                  <h3 class="">{{ $t('gameSingle.notFoundPackage') }}</h3>
                </div>
              </div>
            </div>
          </div>

          <transition name="fade">
            <div class="row justify-content-center" v-if="!this.showImageUpload && game && !game[0].topup_auto">
              <div class="col-12 d-flex justify-content-between">
                <h2 class="text-danger mb-md-4">{{ $t('gameSingle.choosePackeage') }}</h2>
              </div>
              <div class="col-12 mb-3">
                <div class="row" v-if="products && products.length">
                  <div class="col-sm-12 col-md-6 col-xl-4 px-2 position-relative mb-3" v-for="(item, index) in sortedProducts" :key="index">
                    <div class="card card-game d-flex flex-column position-relative cursor-pointer -item blur-dark p-4" v-if="item.status_active && item.name && item.name !== '-'" :class="{'item-active' : this.selectedPacks[item._id] > 0 }" @click="handlePack(item)" style="height: 380px;">
                      <h6 class="position-absolute m-0 bottom-0 end-0"><span class="badge bg-danger rounded-gb text-white" v-if="item.post_status_text == '1'">{{ $t('gameSingle.seller') }}</span></h6>
                      <h6 class="position-absolute m-0 bottom-0 end-0"><span class="badge bg-danger rounded-gb text-white" v-if="item.post_status_text == '2'">{{ $t('gameSingle.promotion') }}</span></h6>
                      <h6 class="position-absolute m-0 bottom-0 end-0"><span class="badge bg-danger rounded-gb text-white" v-if="item.post_status_text == '3'">{{ $t('gameSingle.hot') }}</span></h6>
                      <i v-if="checkCart(item)" @click.stop.prevent="removeItem(item)" title="ลบ" class="cursor-pointer position-absolute top-2 end-2 far fa-check-circle fa-2x"></i>
                      <div class="game-image-id-pass m-auto mb-4">
                        <img v-if="item.img_url && item.img_url !== '/'" class="img-fluid w-100 h-100 m-auto" :src="`${item.img_url}`" alt="" style="object-fit: cover;">                    
                        <!-- <img v-if="item.img_url && item.img_url !== '/'" class="img-fluid w-100 h-100 m-auto" src="../../assets/img/badge/เติมเกม24ชั่วโมง.jpg" alt="" style="object-fit: cover;">                     -->
                        <!-- <img v-else class="mx-auto" width="60" src="@/assets/img/items/item-genshin.png" alt="" style="height: 60px; width: 60px;"> -->
                        <img v-else class="img-fluid w-100 h-100 m-auto" src="https://via.placeholder.com/150" alt="" style="object-fit: cover;">
                      </div>
                      <div class="card-body d-flex flex-column flex-grow-1 p-0">
                        <div v-if="item.name && item.name !== '-'" class="d-flex justify-content-center align-items-center mb-2" style="height: 50px;">
                          <h3 class="text-desc p-0 mt-0">{{ item.name }}</h3>
                        </div>
                        <div class="product-title" v-if="item.price == 0 && item.cost == 0">
                          <h3 class="text-white text-center p-0 mt-3">{{ item.name }}</h3>
                        </div>
                        <div class="product-title" v-if="item.price > 0 && item.cost > 0">
                          <!-- <h3 class="text-desc p-0 mt-3">{{ item.name }}</h3> -->
                          <!-- <p class="m-0 text-center">{{ item.description }}</p> -->
                          <h4 v-if="item.cost == item.price" class="title text-lg price-tag d-flex justify-content-center gap-2 badge px-1 py-1"  style="font-size: 1rem !important;"><i class="fas fa-coins"></i> {{ this.getMemberProfile && marginTypeInPage > 0 ? this.$filters.toCurrency(item.price * (1 - marginTypeInPage / 100)) : this.$filters.toCurrency(item.price) }}</h4>
                          <div v-if="item.cost != item.price" class="title price-tag text-center badge pt-0 d-md-flex mb-2">
                            <span :class="{'discount': !checkCart(item), 'discount-hover': checkCart(item)}" class="discount-mobile me-1 mb-1" style="text-decoration: line-through;"><i class="fas fa-coins"></i> {{ this.$filters.toCurrency(item.cost) }}</span>
                            <span :class="{'text-danger': !checkCart(item), 'text-white': checkCart(item)}" class="price price-mobile">{{ this.getMemberProfile && marginTypeInPage> 0 ? this.$filters.toCurrency(item.price * (1 - marginTypeInPage / 100)) : this.$filters.toCurrency(item.price) }}</span>
                          </div>
                        </div>
                          <div class="quantity-input col-md-4 d-flex justify-content-center align-items-center w-100 mt-3">
                            <button 
                              class="btn-quantity rounded-circle" 
                              style="border-radius: 10px 0 0 10px;" 
                              :style="{
                                backgroundColor: !checkCart(item) ? '#e00b60' : 'rgb(29,29,29)', 
                              }" 
                              type="button" 
                              @click.prevent="decreaseQuantity(item)"
                              :disabled="!selectedPacks[item._id] || selectedPacks[item._id] === 0">-</button>
                            <input 
                              type="text" 
                              class="quantity-value "
                              :style="{
                                backgroundColor: !checkCart(item) ? '' : 'transparent', 
                              }" 
                              :value="selectedPacks[item._id] || 0" 
                              readonly>
                            <button 
                              class="btn-quantity rounded-circle" 
                              style="border-radius: 0 10px 10px 0" 
                              :style="{
                                backgroundColor: !checkCart(item) ? '#e00b60' : 'rgb(29,29,29)', 
                              }" 
                              type="button" 
                              @click.prevent="increaseQuantity(item)">+</button>
                          </div>
                          <div class="position-absolute m-0 top-0 start-0" v-if="item.cost != item.price"><span class="badge savings" style="font-size: 10px;">Save {{ this.getMemberProfile && marginTypeInPage > 0 ? (((item.cost - item.price) / item.cost * 100)+ marginTypeInPage).toFixed(2) : ((item.cost - item.price) / item.cost * 100).toFixed(2) }}%</span></div>
                      </div>
                    </div>

                    <div class="d-flex flex-column position-relative cursor-pointer blur-dark mb-3" v-if="item.status_active && item.name && item.name === '-'" :class="{'item-active' : this.selectedPacks[item._id] > 0 }" @click="handlePack(item)" style="padding: 0 !important;">
                      <h6 class="position-absolute m-0 bottom-0 end-0"><span class="badge bg-danger rounded-gb text-white" v-if="item.post_status_text == '1'">{{ $t('gameSingle.seller') }}</span></h6>
                      <h6 class="position-absolute m-0 bottom-0 end-0"><span class="badge bg-danger rounded-gb text-white" v-if="item.post_status_text == '2'">{{ $t('gameSingle.promotion') }}</span></h6>
                      <h6 class="position-absolute m-0 bottom-0 end-0"><span class="badge bg-danger rounded-gb text-white" v-if="item.post_status_text == '3'">{{ $t('gameSingle.hot') }}</span></h6>
                      <i v-if="checkCart(item)" @click.stop.prevent="removeItem(item)" title="ลบ" class="cursor-pointer position-absolute top-2 end-2 far fa-check-circle fa-2x" style="color: white !important;"></i>
                      <div class="m-auto mb-4" style="width: 200px; height:200px">
                        <img v-if="item.img_url && item.img_url !== '/'" class="img-fluid w-100 h-100 m-auto" :src="`${item.img_url}`" alt="" style="object-fit: cover;">                    
                        <!-- <img v-if="item.img_url && item.img_url !== '/'" class="img-fluid w-100 h-100 m-auto" src="../../assets/img/badge/เติมเกม24ชั่วโมง.jpg" alt="" style="object-fit: cover;">                     -->
                        <!-- <img v-else class="mx-auto" width="60" src="@/assets/img/items/item-genshin.png" alt="" style="height: 60px; width: 60px;"> -->
                        <img v-else class="img-fluid w-100 h-100 m-auto" src="https://via.placeholder.com/150" alt="" style="object-fit: cover;">
                      </div>
                      <div class="card-body d-flex flex-column flex-grow-1 p-0">
                        <div v-if="item.name && item.name !== '-'" class="d-flex justify-content-center align-items-center mb-2" style="height: 50px;">
                          <h3 class="text-desc p-0 mt-0">{{ item.name }}</h3>
                        </div>
                        <div class="product-title" v-if="item.price == 0 && item.cost == 0">
                          <h3 class="text-white text-center p-0 mt-3">{{ item.name }}</h3>
                        </div>
                        <div class="product-title" v-if="item.price > 0 && item.cost > 0">
                          <!-- <h3 class="text-desc p-0 mt-3">{{ item.name }}</h3> -->
                          <!-- <p class="m-0 text-center">{{ item.description }}</p> -->
                          <h4 v-if="item.cost == item.price" class="title text-lg price-tag d-flex justify-content-center gap-2 badge px-1 py-1"  style="font-size: 1rem !important;"><i class="fas fa-coins"></i> {{ this.getMemberProfile && marginTypeInPage > 0 ? this.$filters.toCurrency(item.price * (1 - marginTypeInPage / 100)) : this.$filters.toCurrency(item.price) }}</h4>
                          <div v-if="item.cost != item.price" class="title price-tag text-center badge pt-0 d-md-flex mb-2">
                            <span :class="{'discount': !checkCart(item), 'discount-hover': checkCart(item)}" class="discount-mobile me-1 mb-1" style="text-decoration: line-through;"><i class="fas fa-coins"></i> {{ this.$filters.toCurrency(item.cost) }}</span>
                            <span :class="{'text-danger': !checkCart(item), 'text-white': checkCart(item)}" class="price price-mobile">{{ this.getMemberProfile && marginTypeInPage> 0 ? this.$filters.toCurrency(item.price * (1 - marginTypeInPage / 100)) : this.$filters.toCurrency(item.price) }}</span>
                          </div>
                        </div>

                          <div class="quantity-input col-md-4 d-flex justify-content-center align-items-center w-100 mt-3">
                            <button 
                              class="btn-quantity rounded-circle" 
                              style="border-radius: 10px 0 0 10px;" 
                              :style="{
                                backgroundColor: !checkCart(item) ? 'rgb(29,29,29)' : '#e00b60' , 
                              }" 
                              type="button" 
                              @click.prevent="decreaseQuantity(item)"
                              :disabled="!selectedPacks[item._id] || selectedPacks[item._id] === 0">-</button>
                            <input 
                              type="text" 
                              class="quantity-value "
                              :style="{
                                backgroundColor: !checkCart(item) ? '' : 'transparent', 
                              }" 
                              :value="selectedPacks[item._id] || 0" 
                              readonly>
                            <button 
                              class="btn-quantity rounded-circle" 
                              style="border-radius: 0 10px 10px 0" 
                              :style="{
                                backgroundColor: !checkCart(item) ? 'rgb(29,29,29)' : '#e00b60' , 
                              }" 
                              type="button" 
                              @click.prevent="increaseQuantity(item)">+</button>
                          </div>
                          <div class="position-absolute m-0 top-0 start-0" v-if="item.cost != item.price"><span class="badge savings" style="font-size: 10px;">Save {{ this.getMemberProfile && marginTypeInPage > 0 ? (((item.cost - item.price) / item.cost * 100)+ marginTypeInPage).toFixed(2) : ((item.cost - item.price) / item.cost * 100).toFixed(2) }}%</span></div>
                      </div>
                    </div>

                    <div v-if="!item.status_active" class="card card-game d-flex flex-column position-relative cursor-pointer">
                      <h6 class="position-absolute m-0 bottom-0 end-0"><span class="badge bg-danger rounded-gb text-white" v-if="item.post_status_text == '1'">ขายดี</span></h6>
                      <h6 class="position-absolute m-0 bottom-0 end-0"><span class="badge bg-danger rounded-gb text-white" v-if="item.post_status_text == '2'">โปรโมชัน</span></h6>
                      <h6 class="position-absolute m-0 bottom-0 end-0"><span class="badge bg-danger rounded-gb text-white" v-if="item.post_status_text == '3'">จำนวนจำกัด</span></h6>
                      <i title="ลบ" class="cursor-pointer position-absolute top-2 end-2 far fa-check-circle fa-2x"></i>
                      <!-- <img v-if="item.img_url && item.img_url !== '/'" class="mx-auto" width="60" :src="`${item.img_url}`" alt="" style="height: 60px; width: 60px;"> -->
                      <div class="game-image m-auto">
                        <img v-if="item.img_url && item.img_url !== '/'" class="img-fluid w-100 h-100 m-auto greyscale" :src="`${item.img_url}`" alt="404 Not Found Image" style="object-fit: cover;">
                      </div>
                      <!-- <img v-else class="mx-auto" width="60" src="@/assets/img/items/item-genshin.png" alt="" style="height: 60px; width: 60px;"> -->
                      <div class="card-body d-flex flex-column flex-grow-1 p-2">
                        <div class="product-title">
                          <h3 class="text-desc" style="font-size: 1rem;">{{ item.name }}</h3>
                          <p class="m-0 text-center">{{ item.description }}</p>
                          <p class="m-0 text-center fw-bold mb-2">Sold out</p>
                          <div class="position-absolute m-0 top-0 start-0" v-if="item.cost != item.price"><span class="badge savings" style="font-size: 10px;">Save {{ this.getMemberProfile && marginTypeInPage > 0 ? (((item.cost - item.price) / item.cost * 100)+ marginTypeInPage).toFixed(2) : ((item.cost - item.price) / item.cost * 100).toFixed(2) }}%</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12" v-if="getSelectedItem && !getSelectedItem.length && formErrors['product_list']">
                    <p class="mb-1" style="color: red;">{{ formErrors['product_list'] }}</p>
                  </div>
                </div>
                 <div v-else class="row">
                  <div class="col py-5 text-center">
                    <i class="fas fa-smile fa-2x"></i>
                    <h3 class="">{{ $t('gameSingle.notFoundPackage') }}</h3>
                  </div>
                </div>
              </div>
              <div class="row" v-if="getSelectedItem && getSelectedItem.length">
                <div v-if="game && !game[0].topup_auto">
                  <h2 class="text-danger">{{ $t('gameSingle.selectedPackage') }}</h2>
                  <div class="col-12 card blur-dark shadow-blur border-radius-md position-relative z-index-1 p-3">
                    <div v-for="(item, index) in getSelectedItem" :key="index">
                      <div class="row mb-3 py-3 list-action">
                        <div class="pic col-md-2 col-12 text-center">
                          <img :src="item.img_url" alt="" width="90">
                          <!-- <img src="../../assets/img/badge/เติมเกม24ชั่วโมง.jpg" alt="" width="90"> -->
                        </div>
                          <p class="mb-2 text-white col-md-7 col-12"> {{ item.name }}</p>
                          <!-- <p class="mb-2 text-white col-md-7 col-12"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia dicta fuga quas delectus laudantium hic itaque architecto ad animi quae optio repellat, veniam porro, illum dignissimos pariatur adipisci quibusdam aperiam.</p> -->
                          <span class="text-success col-md-3 col-12 text-end"> {{ this.getMemberProfile && marginTypeInPage > 0 ? this.$filters.toCurrency(item.price * (1 - marginTypeInPage / 100)) : this.$filters.toCurrency(item.price) }} * {{ item.quantity }} {{ $t('gameSingle.item') }}</span>
                      </div>
                    </div>
                    <div class="col-12 d-flex align-items-center justify-content-between">
                      <h4 class="text-white mb-0 fs-4">{{ $t('gameSingle.total') }}</h4>
                      <h3 class="text-danger mb-0 fs-4 text-decoration-underline">
                        {{ getSelectedItem && getSelectedItem.length ? `${this.$filters.toCurrency(+totalPrice)} ฿`  : '0 ฿' }}
                      </h3>                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>

        <div v-if="showImageUpload && !game[0].topup_auto">
          <div class="row image-upload-container">
            <div class="image-upload-card">
              <i class="fa fa-arrow-left fs-5 text-danger undo-text" @click="undoPack"> {{ $t('gameSingle.undo') }}</i>
              <h2 class="upload-title">{{ $t('gameSingle.uploadImg') }}</h2>
              <div class="upload-area" id="drop-area">
                <input type="file" id="fileElem" multiple accept="image/*" @change="handleFiles">
                <label class="upload-label" for="fileElem">
                  <i class="fas fa-cloud-upload-alt"></i>
                  <span>{{ $t('gameSingle.clickImg') }}</span>
                </label>
              </div>
              <div id="gallery" class="image-preview"></div>
              <button class="upload-button" @click="uploadImages">{{ $t('gameSingle.upload') }}</button>
            </div>
          </div>
          <div class="row mt-4">
            <h2 class="text-danger fs-4">{{ $t('gameSingle.detailPack') }}</h2>
            <div class="col-12 card blur-dark shadow-blur border-radius-md position-relative z-index-1 p-3">
              <div v-if="uploadedImages.length" class="uploaded-images mb-3 d-flex justify-content-center align-items-center position-relative">
                <div v-for="(image, index) in uploadedImages" :key="index" class="image-container">
                  <img :src="image" class="uploaded-image" />
                  <button class="delete-button" @click="removeImage(index)">X</button>
                </div>
              </div>
              <div class="mb-3 row">
                <div class="col-md-6 col-12">
                  <label for="price" class="form-label text-white fs-6 mt-2">{{ $t('gameSingle.priceInGame') }}:</label>
                  <input type="number" class="form-control" id="price" v-model="order_printout['price_id_pass']" min="0" @input="updateOrderField('price_id_pass', $event.target.value)">
                </div>
                <div class="col-md-6 col-12">
                  <label for="quantity" class="form-label text-white fs-6 mt-2">{{ $t('gameSingle.amountPack') }}:</label>
                  <input type="number" class="form-control" id="quantity" v-model="order_printout['quantity_id_pass']" min="1" @input="updateOrderField('quantity_id_pass', $event.target.value)">
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <label for="description" class="form-label text-white fs-6 mt-2">{{ $t('gameSingle.description') }}:</label>
                  <textarea id="description" v-model="order_printout['description_id_pass']" class="form-control" rows="4" :placeholder="$t('gameSingle.exDescription')" @input="updateOrderField('description_id_pass', $event.target.value)"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
            id="jkanban-info-modal"
            class="modal fade"
            style="display: none"
            tabindex="-1"
            role="dialog"
          >
          <div class="modal-dialog">
            <div class="modal-content blur-dark-fade">
              <div class="modal-header">
                <h3 class="modal-title text-danger">{{ howto.title }}</h3>
                <button
                  type="button"
                  class="btn-close text-dark"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="pt-4 modal-body">
                <img class="img-fluid" v-if="howto.type == 'howto' && game && game[0].howto_url" :src="game[0].howto_url" alt="" />
                <img class="img-fluid" v-if="howto.type == 'uid' && game && game[0].howto_url" :src="game[0].howto_url" alt="" />
                <img class="img-fluid" v-if="howto.type == 'topup'" :src="how_to_url ? how_to_url : getSetting.page.topup_page.how_to_url" alt="" />
                <div class="" v-if="howto.type == 'order'">
                  <div v-if="getSelectedItem && getSelectedItem.length" class="row blur-dark shadow-blur border-radius-md p-3 mx-sm-0 mx-0 position-relative z-index-1">
                    <div class="col-12 d-flex cart-item" v-for="(item, index) in getSelectedItem" :key="index">
                      <div class="row w-100">
                        <div class="col-md-8 d-flex mb-3 mb-md-0 align-items-center">
                          <div class="pic">
                            <img :src="item.img_url" alt="" width="70">
                          </div>
                          <div class="info w-100">
                            <p class="m-0 text-white"> {{ item.name }}</p>
                            <div class="d-flex gap-3 justify-content-between w-100">
                              <!-- <h5 v-if="this.myWallet < this.totalPrice" class="m-0">ราคา <span class="text-white">{{ this.$filters.toCurrency(item.price) }}</span></h5> -->
                              <h5 class="m-0">{{ $t('gameSingle.price') }} <span class="text-white">{{ this.getMemberProfile && marginTypeInPage > 0 ? this.$filters.toCurrency(item.price * (1 - marginTypeInPage / 100)) : this.$filters.toCurrency(item.price) }}</span></h5>
                              <h5 class="m-0">{{ $t('gameSingle.amount') }} <span class="text-white">{{ item.quantity }} {{$t('gameSingle.item') }}</span></h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-md-0 d-flex gap-3">
                          <div class="d-flex justify-content-end gap-3 align-items-end h-100 w-100">
                            <h5 class="mb-1">
                              {{ $t('gameSingle.inclound') }} 
                              <span class="text-white">
                                {{ this.getMemberProfile && marginTypeInPage > 0 ? this.$filters.toCurrency(item.price * (1 - marginTypeInPage / 100) * item.quantity) : this.$filters.toCurrency(item.price * item.quantity) }}
                              </span>
                            </h5>
                            <!-- <h5 v-if="this.myWallet >= this.totalPrice" class="mb-1">รวม <span class="text-white">{{ this.$filters.toCurrency(item.price * item.quantity) }}</span></h5> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 d-flex align-items-center justify-content-between">
                        <h4 class="text-danger mb-0">{{ $t('gameSingle.total') }}</h4>
                        <h3 class="text-danger mb-0">
                          {{ getSelectedItem && getSelectedItem.length 
                            ? `${this.$filters.toCurrency(+totalPrice)} ฿`
                            : '0 ฿' 
                          }}
                        </h3>
                        <!-- <h3 class="text-danger mb-0">{{ getSelectedItem && getSelectedItem.length ? `${this.$filters.toCurrency(totalPrice)} ฿`  : '0 ฿' }}</h3> -->
                    </div>
                  </div>
                  <div class="mt-3">
                    <h5 class="text-danger">{{ $t('historyOrder.information') }}</h5>
                    <div v-if="game && game[0].game_form_field_arr" class="row blur-dark shadow-blur border-radius-md pt-4 p-3 mx-sm-0 mx-0 position-relative z-index-1">
                      <div v-for="(f,index) in game[0].game_form_field_arr" :key="index" class="col-6 mt-lg-n2 mt-4 mb-3">
                        <div v-if="f.type != 'server' && f.type != 'game_server'">
                          <label class="m-0 text-danger" for="">{{f.name}}</label>
                          <p class="text-white">{{ order_printout[f.name] }}</p>
                        </div>
                        
                        <div v-if="f.type == 'game_server'">
                          <label class="m-0 text-danger" for="">Server</label>
                          <p class="text-white">{{ this.order.server_name }}</p>
                        </div>
                        
                      </div>
                      <div class="row">
                        <div class="col-12 mb-4" v-if="this.attach_img_url">
                          <div class="row">
                            <div class="col-md-4 col-6" v-for="(item, index) in this.attach_img_url" :key="index">
                              <img class="me-3" :src="item" alt="" style="width: 100%; height: 150px;">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-md-6" v-if="order_printout['price_id_pass']">
                            <label class="m-0 text-danger" for="">{{ $t('gameSingle.priceInGame') }}:</label>
                            <p class="text-white">{{ order_printout['price_id_pass'] }}</p>
                          </div>
                          <div class="col-12 col-md-6" v-if="order_printout['quantity_id_pass']">
                            <label class="m-0 text-danger" for="">{{ $t('gameSingle.amountPack') }}:</label>
                            <p class="text-white">{{ order_printout['quantity_id_pass'] }}</p>
                          </div>
                          <div class="col-12 col-md-6" v-if="order_printout['description_id_pass']">
                            <label class="m-0 text-danger" for="">{{ $t('gameSingle.description') }}:</label>
                            <p class="text-white">{{ order_printout['description_id_pass'] }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="row">
                      <div class="col-12 d-lg-flex justify-content-between align-items-center mt-3">
                        <div class="">
                          <div class="form-check ">
                            <input
                              id="btncheck2"
                              class="form-check-input"
                              type="checkbox"
                              v-model="confirm_policy"
                            />
                            <label for="btncheck2" class="custom-control-label">
                              {{ $t('gameSingle.agree') }}
                              <a href="/terms-of-services" target="_blank" class="text-danger">{{ $t('gameSingle.condition') }}</a>
                            </label>
                          </div>
                          <span v-if="!confirm_policy" class="text-danger">{{ $t('gameSingle.roles') }}</span>
                        </div>
                        <button class="btn px-4 w-100 w-md-auto text-lg btn-outline-danger mb-0 mt-3 mt-lg-0" :disabled="!confirm_policy" data-bs-dismiss="modal"
                                aria-label="Close" @click="confirmPayment()">{{ $t('gameSingle.submit') }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="jkanban-info-modal-backdrop"
          class="fixed inset-0 z-40 hidden bg-black opacity-50">
        </div>
        
        <div class="j-space my-3"></div>
        
        <div v-if="getMemberProfile">
          <div class="row">
            <div class="col-12 my-2" v-if="promotions.length <= 0">
              <h5 class="text-danger px-0 mb-0 w-auto">* {{ $t('gameSingle.notPromotion') }}</h5>
            </div>
          </div>
          <div class="row" v-if="promotions.length > 0">
            <div class="col-12 my-2">
              <h5 class="text-danger px-0 mb-0 w-auto">* {{ $t('gameSingle.thisPromotion') }}</h5>
              <p class="text-white ms-3 fw-bold p-0">{{ $t('gameSingle.rolesPromotion') }}</p>
            </div>
            <div class="col-12 mb-3">
              <Carousel ref="myCarousel" v-model="currentSlide" v-bind="settings" :breakpoints="breakpoints">
                <Slide v-for="(item, index) in promotions" :key="index">
                  <div class="slide-con w-100" style="height: 150px; padding: 1rem;" @click="$router.push('/coin-topup')">
                    <div class="card card-box shadow h-100 zoom-product"  data-scroll href="#" @click="goToBuy(card._id)">
                      <div class="card-gradient h-100">
                        <div class="row g-0 h-100" style="width: 100%;">
                          <div class="col-3 col-md-3 d-flex align-items-center me-3">
                            <img class="w-100 img-fluid" src="../../../public/logo.png" alt="">
                          </div>
                          <div class="col-7 col-md-7">
                            <div class="text-start ms-2 w-100 mt-2">
                              <h5 class="card-title">{{ item.name_promotion }}</h5>
                            </div>
                          </div>
                          <button class="btn btn-danger w-60 p-1 position-absolute end-0 bottom-0 rounded-gb" style="font-size: 14px;">ไปหน้าเติมเครดิต</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slide>
                <template #addons>
                  <Navigation />
                </template>
              </Carousel>
            </div>
          </div>
          <div class="row" v-if="getSelectedItem && !getSelectedItem.length">
            <div class="col-12 my-2">
              <h5 class="text-danger px-0 mb-0 w-auto">* {{ $t('gameSingle.package') }}</h5>
            </div>
          </div>
          <div class="row" v-if="this.myWallet < totalPrice">
            <div class="col-12">
              <div class="row" v-if="banks && banks.length">
                <div class="col-6 mb-3" v-if="banks[0]">
                  <div
                    class="payment-method-card"
                    :class="{ selected: selectedPaymentMethod == 'bank' }"
                    @click.prevent="selectPaymentMethod(banks, 'bank')"
                  >
                    <i class="fas fa-university"></i>
                    <span>{{ $t('coinTopup.bank') }}</span>
                    <i
                      v-if="selectedPaymentMethod == 'bank'"
                      class="fas fa-check-circle check-icon"
                    ></i>
                  </div>
                </div>
                <div class="col-6 mb-3" v-if="banks[1]">
                  <div
                    class="payment-method-card"
                    :class="{
                      selected: selectedPaymentMethod == 'wallet',
                    }"
                    @click.prevent="selectPaymentMethod(banks, 'wallet')"
                  >
                    <i class="fas fa-wallet"></i>
                    <span>{{ $t('coinTopup.wallet') }}</span>
                    <i
                      v-if="selectedPaymentMethod == 'wallet'"
                      class="fas fa-check-circle check-icon"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="totalPrice && this.myWallet < totalPrice" @click="$router.push('/coin-topup')" style="cursor: pointer;">
            <div class="col-12 my-2">
              <div class="special-con d-flex shiny-effect">
                <div class="col-4 col-md-2 d-flex align-items-center">
                  <img class="w-100 img-fluid" src="../../../public/logo.png" alt="">
                </div>
                <div class="text-con col-7 col-md-10 ms-4">
                  <p class="fs-5 text-white fw-bolder">{{ $t('gameSingle.promotionTitle') }}</p>
                  <p class="fs-6 text-secondary" v-html="$t('gameSingle.promotionDesc')"></p>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="row">

            <div class="col-12 mb-3" v-if="this.selectedPaymentMethod == 'bank' && dataBank.status_active || this.myWallet >= totalPrice && dataWallet == null">
              <div class="row" v-if="totalPrice">
                <div class="col-12 mx-auto mt-3 position-relative">
                  <div class="row" v-if="getSelectedItem && getSelectedItem.length">
                    <div class="col-12 my-2">
                      <h5 class="text-danger px-0 mb-0 w-auto ">{{ $t('gameSingle.pricePay') }}</h5>
                      <h2 class="text-white d-flex justify-content-start gap-3 align-items-center" v-if="this.myWallet < this.totalPrice">{{ getSelectedItem && getSelectedItem.length ? `${this.$filters.toCurrency(getQrCode?.amount ? (getQrCode?.amount-this.myWallet) : (totalPrice-this.myWallet))}`  : '0' }} <span><button @click.prevent="copyToClipboard(getQrCode?.amount ? getQrCode?.amount : totalPrice - this.myWallet, 'คัดลอกยอดเงินที่ต้องชำระแล้ว')" class="btn btn-xs btn-outline-white m-0 p-1"><i class="fas fa-copy"></i> {{ $t('copy') }}</button></span></h2>
                      <h2 class="text-white d-flex justify-content-start gap-3 align-items-center" v-if="this.myWallet >= this.totalPrice">{{ getSelectedItem && getSelectedItem.length ? `${this.$filters.toCurrency(getQrCode?.amount ? getQrCode?.amount : totalPrice)}`  : '0' }} <span><button @click.prevent="copyToClipboard(getQrCode?.amount ? getQrCode?.amount : totalPrice, 'คัดลอกยอดเงินที่ต้องชำระแล้ว')" class="btn btn-xs btn-outline-white m-0 p-1"><i class="fas fa-copy"></i> {{ $t('copy') }}</button></span></h2>
                    </div>
                    <div class="d-flex mb-3" v-if="this.myWallet < this.totalPrice">
                      <div class="bank-logo">
                        <img class="img-responsive rounded-1" :src="dataBank.url_img" alt="">
                      </div>
                      <div class="bank-detail">
                        <h5 class="m-0 text-white">{{ dataBank.bank_name }}</h5>
                        <h5 class="m-0 text-white d-flex justify-content-between aling-items-center">{{ dataBank.account_no }} <button @click.prevent="copyBank(dataBank)" class="btn btn-xs btn-outline-white m-0 p-1 mx-2"><i class="fas fa-copy"></i> {{ $t('copy') }}</button></h5>
                        <h6 class="m-0 text-white">{{ dataBank.account_name }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>    
            </div>

            <div class="col-12 mb-3" v-if="this.selectedPaymentMethod == 'wallet' && dataWallet.status_active && dataBank == null">
              <div class="row" v-if="totalPrice">
                <div class="col-12 mx-auto mt-3 position-relative">
                  <div class="row" v-if="getSelectedItem && getSelectedItem.length">
                    <div class="col-12 my-2">
                      <h5 class="text-danger px-0 mb-0 w-auto ">{{ $t('gameSingle.pricePay') }}</h5>
                      <h2 class="text-white d-flex justify-content-start gap-3 align-items-center" v-if="this.myWallet < this.totalPrice">{{ getSelectedItem && getSelectedItem.length ? `${this.$filters.toCurrency(getQrCode?.amount ? (getQrCode?.amount - this.myWallet) : (totalPrice - this.myWallet))}`  : '0' }} <span><button @click.prevent="copyToClipboard(getQrCode?.amount ? getQrCode?.amount : totalPrice - this.myWallet, 'คัดลอกยอดเงินที่ต้องชำระแล้ว')" class="btn btn-xs btn-outline-white m-0 p-1"><i class="fas fa-copy"></i> {{ $t('copy') }}</button></span></h2>
                      <h2 class="text-white d-flex justify-content-start gap-3 align-items-center" v-if="this.myWallet >= this.totalPrice">{{ getSelectedItem && getSelectedItem.length ? `${this.$filters.toCurrency(getQrCode?.amount ? getQrCode?.amount : totalPrice)}`  : '0' }} <span><button @click.prevent="copyToClipboard(getQrCode?.amount ? getQrCode?.amount : totalPrice, 'คัดลอกยอดเงินที่ต้องชำระแล้ว')" class="btn btn-xs btn-outline-white m-0 p-1"><i class="fas fa-copy"></i> {{ $t('copy') }}</button></span></h2>
                    </div>
                    <div class="d-flex mb-3" v-if="this.myWallet < this.totalPrice">
                      <div class="bank-logo">
                        <img class="img-responsive rounded-1" :src="dataWallet.url_img" alt="">
                      </div>
                      <div class="bank-detail">
                        <h5 class="m-0 text-white">{{ dataWallet.bank_name }}</h5>
                        <h5 class="m-0 text-white d-flex justify-content-between aling-items-center">{{ dataWallet.account_no }} <button @click.prevent="copyBank(dataWallet)" class="btn btn-xs btn-outline-white m-0 p-1 mx-2"><i class="fas fa-copy"></i> {{ $t('copy') }}</button></h5>
                        <h6 class="m-0 text-white">{{ dataWallet.account_name }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>    
            </div>
          </div>

          <div class="row mb-3" v-if="qrCodeImage && this.myWallet < this.totalPrice && this.selectedPaymentMethod == 'bank'">
            <div class="col-md-4 col-6">
              <!-- QR Code container with v-html -->
              <div
                v-html="qrCodeImage"
                class="w-100"
                @click="downloadQRCode"
                :class="{ 'animate-download': animationClass }"
                style="cursor: pointer;"
              ></div>
          
              <!-- Hidden link for download -->
              <a ref="downloadLink" :href="downloadUrl" download="qrcode.svg" style="display: none;"></a>
            </div>
            <div class="col-md-8 mt-3 col-6">
              <button
                class="btn btn-xs btn-outline-white m-0 p-1"
                @click="downloadQRCode"
              >
                <i class="fas fa-download"></i> Download QRCode
              </button>
            </div>
          </div>

          <div class="row mb-3" v-if="QrCodeTrue && this.myWallet < this.totalPrice && this.selectedPaymentMethod == 'wallet'">
            <div class="col-md-4 col-6">
              <!-- QR Code container -->
              <img
                :src="QrCodeTrue"
                alt="QR Code"
                class="w-100"
                @click="downloadQRCodeTrue"
                :class="{ 'animate-download': animationClass }"
                style="cursor: pointer;"
              />
          
              <!-- Hidden link for download -->
              <a ref="downloadLinkTrue" :href="downloadUrlTrue" download="qrcode.jpg" style="display: none;"></a>
            </div>
            <div class="col-md-8 mt-3 col-6">
              <button
                class="btn btn-xs btn-outline-white m-0 p-1"
                @click="downloadQRCodeTrue"
              >
                <i class="fas fa-download"></i> Download QRCode
              </button>
            </div>
          </div>
          
          <div class="j-space my-3"></div>
          <div class="row" :class="getSelectedItem != '' && this.myWallet < this.totalPrice && this.selectedPaymentMethod != null ? '' : 'd-none'">
            <div class="col-12 mb-3 mb-md-0">
              <label for="slip">{{ $t('coinTopup.attach') }}</label>
              <form
                id="dropzone2"
                ref="dropzone2"
                :action="`${API_URL}/file/upload`"
                class="form-control dropzone d-flex justify-content-start align-items-center text-center"
                @click="resetSlip()"
              >
                <div class="fallback">
                  <input name="file" type="file" />
                </div>
                <a v-if="order.slip_img_url" class=" order-2 dz-remove btn btn-danger z-index-1 cursor-pointer mt-3" role="button" @click="resetSlip()">{{ $t('coinTopup.deleteFile') }}</a>
              </form>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-end mt-3">
              <button v-if="this.getSelectedItem && this.getSelectedItem.length && totalPrice" class="btn h-100 px-4 w-100 w-md-auto text-lg btn-outline-danger mb-0" @click="confirmPaymentCheck()">{{ $t('gameSingle.submitPay') }}</button>
              <button v-if="this.showImageUpload && this.getSelectedItem && !this.getSelectedItem.length" class="btn h-100 px-4 w-100 w-md-auto text-lg btn-outline-danger mb-0" @click="confirmPaymentCheck()">{{ $t('gameSingle.sendForm') }}</button>
            </div>
          </div>
        </div>
        <div class="" v-if="!getMemberProfile">
          <div class="row">
            <div class="col-12 d-flex justify-content-end mt-3">
              <button class="btn h-100 px-4 w-100 w-md-auto text-lg btn-outline-danger mb-0" @click="pleaseLogin">{{ $t('gameSingle.rolesLogin') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12 comment-con" v-if="game && game[0].compare_prices_img_url">
        <div class="card bg-white shadow-lg rounded-lg overflow-hidden mb-4" :class="isMobile ? '' : 'd-none' ">
          <div class="card-header bg-primary text-white py-3">
            <h4 class="mb-0 font-weight-bold text-white">Review</h4>
          </div>
          <div class="card-body p-4">
            <div class="fb-comments" 
            data-href="https://termgame.goldenbug.co/" 
            data-width="100%" 
                 data-numposts="5"
                 data-order-by="reverse_time">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="container pt-4 mt-md-n7"> -->
  
  <div class="j-space my-3"></div>
  
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
// import NavPill from "./components/NavPill.vue"
// import InputNumber from "./components/InputNumber.vue"
// import AnimatedStatisticsCard from "./projects/components/AnimatedStatisticsCard.vue";
// import SoftButton from "@/components/SoftButton.vue";
import AOS from "aos";
import axios from 'axios';
import 'aos/dist/aos.css';
import Rellax from 'rellax';
import setNavPills from "@/assets/js/nav-pills.js";
import Dropzone from "dropzone";
import QrCodeTrue from '../../assets/img/qrcode-true.jpg'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { Carousel, Navigation, Slide } from 'vue3-carousel'

// const API_ROOT_URL = process.env.VUE_APP_API_URL;
const API_ROOT_URL = "https://termgame-api.goldenbug.co/api";

export default {
  components: {
    // AnimatedStatisticsCard,
    Navbar,
    AppFooter,
    Carousel,
    Slide,
    Navigation,
    // NavPill,
    // InputNumber,
    // SoftInput,
    // SoftSwitch,
    // SoftButton,
  },
  data() {
    return {
      confirm_policy: false,
      cart: 0,
      howto: {
        title: 'How to ?',
        type: 'uid'
      },
      order_printout:{},
      order: {
        uid: '',
        game_username: '',
        game_password: '',
        server_name: '',
        character: '',
        loginwith: '',
        level: '',
        other: '',
        recived_bank_id: '',
        detail: '',
        remark: '',
        issue_remark: '',
        slip_img_url: '',
        attach_img_url: '',
        status_use_credit: false,
        product_buy: [],
      },
      topupData: {
        creadit_total_topup: "",
        bank_id: "",
        receipt_url: "",
      },
      payment: null,
      formErrors: {},
      isActive: false,
      API_URL: API_ROOT_URL,
      token: window.localStorage.getItem('jcr_token') || null,
      slipDropzone: null,
      how_to_url: '',
      dropzones: [],
      attach_img_url: [],
      verify: window.localStorage.getItem("verify") || false,
      qr_total_topup: '',
      qrCodeImage: null,
      downloadUrl: '',
      animationClass: false,
      currentSlide: 0,
      isMobile: window.innerWidth <= 991, // Check if the device is mobile
      selectedPaymentMethod: null,
      dataBank: null,
      dataWallet: null,
      selectedPacks: [],
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
        wrapAround: true,
      },
      breakpoints: {
        400: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        700: {
          itemsToShow: 1.7,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
      },
      downloadUrlTrue: '', // URL สำหรับการดาวน์โหลด
      QrCodeTrue,
      showPackageSelection: false,
      showImageUpload: false,
      uploadedImages: [],
      price_id_pass: null,
      quantity_id_pass: null,
      description_id_pass: '',
      prepareData: null,
      isValid: true,
      pattern: null,
      formCurrect: false,
      marginTypeInPage: 0, 
      gameIsAuto: null
    }
  },
  async created() {
    
    await this.fetchBannerById(this.gameId);
    this.fetchBanks();
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    this.getMyCredit();
    this.fetchPromotions();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    this.$store.dispatch('InitialCart');
    window.removeEventListener("resize", this.handleResize);

  },
  async mounted() {
    await this.fetchGameById(this.gameId);
    await this.fetchProducts(this.gameId);
    window.addEventListener("resize", this.handleResize);
    await this.filterMarginType();


    const apiPattern = this.game[0]?.pattern; 
    if (apiPattern) {
      try {
        this.pattern = new RegExp(apiPattern.slice(1, -1));
      } catch (e) {
        console.error('Invalid regex pattern from API:', e);
      }
    }

    const game_has_server = this.game[0].game_form_field_arr.find(item => item.type == 'game_server')
    if (game_has_server && game_has_server?.data) {
      this.order_printout['game_server'] = game_has_server?.data[0]
    }

    AOS.init();
    setNavPills();
    if (document.getElementsByClassName('rellax')) {
      new Rellax('.rellax', {
        speed: 2,
        center: true,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: true
      });

    }

    setTimeout(async () => {
      Dropzone.autoDiscover = false;
      
      if (this.getMemberProfile) {
        
        this.slipDropzone = new Dropzone(this.$refs.dropzone2, {
          // addRemoveLinks: true,
          dictDefaultMessage: this.$t('coinTopup.chooseSlip'),
          dictRemoveFile: this.$t('coinTopup.slipAgain'),
          maxFiles: 1,
          init: function() { 
            this.on("maxfilesexceeded", async function(file) { 
              await this.removeAllFiles(); 
              this.addFile(file); 
            }); 
          },
          headers: {
            'Authorization': this.token
          },
          acceptedFiles: 'image/*',
        });
        await this.slipDropzone.removeAllFiles()
        var self = this;
        this.slipDropzone.on("success", function(file, res){ 
          try {
            const file_name = res.datas.file_name;
            self.order.slip_img_url = `https://termgame-api.goldenbug.co/files/${file_name}`;
          } catch (error) {
            self.checkLogin();
          }
        });
      
        this.slipDropzone.on("error", (file, res) => {
          this.order.slip_img_url = 'error'
          this.$swal({
            icon: "error",
            title: this.$t('coinTopup.slipTitleError'),
            text: this.$t('coinTopup.slipMessageError'),
            type: 'warning',
          });
          // this.removeFile(file);
        });
      }
    }, 1000);

    this.$nextTick(() => {
    if (window.FB) {
      window.FB.XFBML.parse();
    }
    })
  },
  computed: {
    ...mapState(['mockData']),
    ...mapGetters(['checkCart', 'totalPrice', 'itemsByCategory', 'game', 'products', 'banks', 'getSetting', 'getSelectedItem', 'gameBanner', 'myWallet', 'getMemberProfile', 'isLoggedIn', 'getSettingCMSAll', 'getQrCode', 'promotions']),
    gameId() {
      const fullParam = this.$route.params.game_id;
      return fullParam.split('-').pop();
    },
    formattedText() {
      return this.game && this.game[0].pack_description ? this.game[0].pack_description.replace(/\n/g, '<br>').replace(/\t/g, '&emsp;') : '';
    },
    sortedProducts() {
      // console.log('this.products: ', this.products);
    return this.products.slice().sort((a, b) => {
      const aStatus = parseInt(a.post_status_text);
      const bStatus = parseInt(b.post_status_text);

      // ตรวจสอบสถานะต่าง ๆ
      if (aStatus && !bStatus) {
        return -1;
      } else if (!aStatus && bStatus) {
        return 1;
      } else if (aStatus && bStatus) {
        return aStatus - bStatus;
      } else {
        return 0;
      }
    });
    },
  },
  methods: {
    initializeDropzones(index, id) {
      const refName = `refDropzone${id}`;
      const dropzoneElement = this.$refs[refName];
      const dropzone = new Dropzone(dropzoneElement, {
        dictDefaultMessage: this.$t('coinTopup.chooseSlip'),
        dictRemoveFile: this.$t('coinTopup.slipAgain'),
        maxFiles: 1,
        headers: {
          'Authorization': this.token
        },
        acceptedFiles: 'image/*',
      });

      this.dropzones.push(dropzone);

      dropzone.on('addedfile', function(file) {
        while (this.files.length > this.options.maxFiles) this.removeFile(this.files[0]);
      });

      dropzone.on("success", (file, res) => {
        try {
          const file_name = res.datas.file_name;
          this.attach_img_url[id] = `https://termgame-api.goldenbug.co/files/${file_name}`;
        } catch (error) {
          this.checkLogin();
        }
      });
      
      dropzone.on("error", (file, res) => {
        this.$swal({
          icon: "error",
          title: this.$t('coinTopup.slipTitleError'),
          text: this.$t('coinTopup.slipMessageError'),
          type: 'warning',
        });
        this.removeFile(file);
      });
    },
    selectFile(index) {
      const refName = `refDropzone${index}`;
      this.$refs[refName].click();
    },
    destroyDropzone(index) {
      const dropzone = this.dropzones[index];
      if (dropzone) {
        this.dropzones.splice(index, 1);
      }
    },
    removeProduct(ref) {
      const product = this.order.product_buy.find(item => item.product_id === ref);

      if (product) {
        const index = this.order.product_buy.indexOf(product);

        if (index !== -1) {
          this.order.product_buy.splice(index, 1);
        }
      }
    },
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(['fetchProducts', 'fetchGameById', 'fetchBanks', 'fetchBannerById', 'getMyCredit', 'fetchPromotions']),
    resetSlip() {
      this.slipDropzone.removeAllFiles(true);
      this.order.slip_img_url = '';
    },
    // resetAttach(index) {
    //   return this.$swal({
    //       icon: "question",
    //       title: "ลบภาพไอเทม ?",
    //       text: "",
    //       type: 'warning',
    //     }).then((result) => {
    //       if (result.isConfirmed) {
    //         if (this.attach_img_url.hasOwnProperty(index)) {
    //           delete this.attach_img_url[index];
    //         }
    //       }
    //     });
    // },
    pleaseLogin() {
      
      window.localStorage.setItem("selectedItem", JSON.stringify(this.getSelectedItem));
      return this.$router.push('/signin');
    },
    checkLogin() {
      if (!this.getMemberProfile) {
        return this.$swal({
          icon: "error",
          title: this.$t('errorTitle'),
          text: this.$t('coinTopup.validSignup'),
          type: 'error',
        }).then((result) => {
          if (result.isConfirmed) {
            return this.$router.push('/signin')
          }
        });
      }
    },
    hideModal() {
      document
            .querySelector("#jkanban-info-modal")
            .classList.remove("show");
          document.querySelector("body").classList.remove("modal-open");
          document.querySelector("body").style = "";
          document.querySelector(".modal-backdrop").remove();
    },
    async showModal(param) {
      this.howto.type = param;
      const bootstrap = this.$store.state.defaultStore.bootstrap;

      let jkanbanInfoModal = document.getElementById(
        "jkanban-info-modal"
      );

      var myModal = new bootstrap.Modal(jkanbanInfoModal, {
        show: true
      });
      myModal.show();
    },
    copyBank(payment) {
      // console.log('payment: ', payment);
      if (payment != 'credit') {
        this.copyToClipboard(payment.account_no);
      }
    },
    copyToClipboard(bank_number, text = null) {
      try {
        const el = document.createElement('textarea');
        el.value = bank_number;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        const Toast = this.$swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })

        Toast.fire({
          icon: 'success',
          title: text ? text : this.$t('coinTopup.bankCopy')
        })
      } catch (error) {
        
      }
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      this.$nextTick(() => {
        this.$refs.game_password.focus();
      });
    },
    minimizeDetail(param) {
      if (param) {
        const gameDetail = document.getElementById('game-detail');
        if (gameDetail) {
          gameDetail.classList
        }
      }
    },
    toggleActive() {
      this.isActive = !this.isActive;
    },
    async addItem(index, item) {
      item.quantity = 1;
      await this.$store.dispatch('selectItem', item);
      setTimeout(() => {
        this.initializeDropzones(index, item._id);
      }, 100);
      const selectedItems = this.getSelectedItem;

      let resultPrice = 0;
      for (const item of selectedItems) {
        resultPrice += this.getMemberProfile && this.marginTypeInPage > 0 ? item.price * (1 - this.marginTypeInPage / 100)  : item.price;
      }
      this.qr_total_topup =
      parseFloat(resultPrice)
      this.genGenQrCode(this.qr_total_topup)
      return resultPrice;
    },
    async removeItem(item){
      await this.$store.dispatch('removeItemFromCart', item);
      return console.log(this.getSelectedItem);
    },

    increaseQuantity(item) {
      if (!this.selectedPacks[item._id]) {
        this.selectedPacks[item._id] = 0;
      }
      this.selectedPacks[item._id]++;
      this.$forceUpdate(); // เพื่อให้ Vue อัปเดต UI
      this.calculateTotalPrice();
    },

    decreaseQuantity(item) {
      if (this.selectedPacks[item._id] && this.selectedPacks[item._id] > 0) {
        this.selectedPacks[item._id]--;
        if (this.selectedPacks[item._id] === 0) {
          delete this.selectedPacks[item._id];
        }
        this.$forceUpdate(); // เพื่อให้ Vue อัปเดต UI
        this.calculateTotalPrice();
      }
    },
    calculateTotalPrice() {
      const selectedItems = Object.keys(this.selectedPacks).map(itemId => {
        const item = this.products.find(product => product._id === itemId);
        return {
          ...item,
          quantity: this.selectedPacks[itemId]
        };
      });
      
      const totalPrice = selectedItems.reduce((total, item) => {        
        const price = this.getMemberProfile && this.marginTypeInPage > 0 ? item.price * (1 - this.marginTypeInPage / 100)  : item.price
        return total + price * (item.quantity || 1); 
      }, 0);
      // ส่งข้อมูลไปยัง store
      this.$store.dispatch('updateSelectedItems', selectedItems);
      this.$store.dispatch('updateTotalPrice', totalPrice);

      // อัปเดตค่าอื่นๆ ตามที่ต้องการ
      this.qr_total_topup = parseFloat(totalPrice);
      this.genGenQrCode(this.qr_total_topup);
    },
    getSelectedPackages() {
      return this.selectedPacks.map(pack => ({
        id: pack._id,
        name: pack.name,
        quantity: pack.quantity,
        price: pack.price,
        totalPrice: this.getMemberProfile && this.marginTypeInPage > 0 ? pack.price * (1 - this.marginTypeInPage / 100)  : pack.price * pack.quantity
      }));
    },
    confirmPaymentCheck() {
      if (this.validateForm()) {
        this.checkLogin();
        if ( this.order.slip_img_url === '' && this.myWallet < this.totalPrice) {
          return this.$swal({
            icon: 'warning',
            title: this.$t('important'),
            text: this.$t('gameSingle.validSlip'),
            type: 'warning',
          });
        }
        
        if (this.order.slip_img_url == 'error') {
          return this.$swal({
            icon: 'warning',
            title: this.$t('important'),
            text: this.$t('coinTopup.validateFormSlip'),
            type: 'warning',
          });
        }
        
        this.howto.title = this.$t('gameSingle.dataOrder')
        this.howto.type = 'order'
        this.showModal('order');
      } else if (this.showImageUpload && this.getSelectedItem && !this.getSelectedItem.length){
        if (!this.attach_img_url.length){
          return this.$swal({
            icon: 'warning',
            title: this.$t('important'),
            text: this.$t('gameSingle.imgOrder'),
            type: 'warning',
          });
        }
        this.submitForm();
      }
    },        
    confirmPayment() {
      if (this.validateForm()) {
        this.checkLogin();
        if (this.order.slip_img_url === '' && this.myWallet < this.totalPrice) {
          return this.$swal({
            icon: 'warning',
            title: this.$t('important'),
            text: this.$t('gameSingle.validSlip'),
            type: 'warning',
          });
        }

        this.$swal({
          title: this.$t('gameSingle.submitOrder'),
          text: `${this.$t('gameSingle.warningText')}`,
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: this.$t('close'),
          confirmButtonText: this.$t('gameSingle.confirmButton'),
          customClass: {
            confirmButton: "btn bg-danger",
            cancelButton: "btn bg-dark",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.submitForm();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal.dismiss;
          }
        });
      }
    },
    validateForm() {
      let isValid = true;
      this.formErrors = {};  // Reset the form errors

      for (const ref in this.$refs) {
        const input = this.$refs[ref];
        if (input instanceof HTMLElement && input.hasAttribute('data-error-display')) {
          const errorDisplay = input.getAttribute('data-error-display');

          if (this.getSelectedItem && !this.getSelectedItem.length) {
            this.formErrors['product_list'] = `กรุณาเลือกแพ็คเกจ`;
            isValid = false;
          }

          if (!input.value.trim()) {
            this.formErrors[ref] = ( errorDisplay == 'เซิฟเวอร์' || errorDisplay == 'เซิร์ฟเวอร์' || errorDisplay == 'วิธีชำระเงิน' ) ? `กรุณาเลือก ${errorDisplay}` : `กรุณากรอก ${errorDisplay}`;
            if (isValid) {
              input.focus(); // focus on the first field that has an error
            }
            isValid = false;
          }

          const inputPattern = this.pattern;
          if (inputPattern) {
            if (!this.formCurrect) {
              this.formErrors[ref] = `กรุณากรอก ${errorDisplay} ให้ถูกต้องตามรูปแบบที่กำหนด`;
              if (isValid) {
                input.focus(); // focus on the first field that has an error
              }
              isValid = false;
            }
          }
        }
      }

      return isValid;
    },
    validateInput(fieldName, value) {
      // ตรวจสอบข้อมูลตาม pattern ที่กำหนด
      if (this.pattern && !this.pattern.test(value)) {
        this.formCurrect = false
        this.formErrors[fieldName] = `กรุณากรอก ${fieldName} ให้ถูกต้องตามรูปแบบที่กำหนด`;
      } else {
        // ถ้าข้อมูลถูกต้อง ให้ลบข้อความข้อผิดพลาด
        this.formCurrect = true
        delete this.formErrors[fieldName];
      }

      // อัปเดตค่าใน order_printout
      this.updateOrderField(fieldName, value);
    },
    submitForm() {
      if (this.getSelectedItem && this.getSelectedItem.length || (this.showImageUpload && this.getSelectedItem && !this.getSelectedItem.length)) {
        // เตรียมข้อมูลการสั่งซื้อ
        this.order.product_buy = this.getSelectedItem.map(item => ({
          product_name: item.name,
          product_price: this.getMemberProfile && this.marginTypeInPage > 0 ? item.price * (1 - this.marginTypeInPage / 100)  : item.price,
          product_id: item._id,
          product_total: item.quantity ? parseInt(item.quantity) : 0 ,
          type_topup: item.game_id.topup_auto
        }));

        if (this.prepareData) {
          this.order.product_buy.push(this.prepareData); 
        }
        this.order_printout = {
          ...this.order_printout,
          img_url: this.attach_img_url,
        };
        this.order.detail = JSON.stringify(this.order_printout);

        // เตรียมข้อมูลสำหรับการเติมเครดิต
        if (this.myWallet < this.totalPrice) {
          this.topupData.creadit_total_topup = (this.totalPrice - this.myWallet).toFixed(2);
        } else {
          this.order.recived_bank_id = 'credit';
          this.order.status_use_credit = true;
          this.topupData.creadit_total_topup = this.totalPrice;
        }
        this.topupData.receipt_url = this.order.slip_img_url;
        
        // เก็บข้อมูลใน Local Storage
        localStorage.setItem('orderData', JSON.stringify(this.order));
        localStorage.setItem('totalPrice', JSON.stringify(this.totalPrice));

        // ตรวจสอบยอดเงินกับ API
        fetch(`https://termgame-admin.goldenbug.co/api/service/checkPrice?balance=${this.totalPrice}`)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            if (data.success) {
              if (this.getSelectedItem && this.getSelectedItem.length && this.totalPrice) {
                // ส่งคำสั่งเติมเครดิต
                this.$store.dispatch("topupCredit", this.topupData)
                  .then(response => {
                    if (response.data.success) {
                      // ถ้าการเติมเครดิตสำเร็จ, ย้ายไปหน้า check-order
                      this.$router.push('/check-order');
                    } else {
                      throw new Error(response.data.message || "ไม่สามารถทำรายการได้ในขณะนี้");
                    }
                  })
                  .catch(error => {
                    console.error("เกิดข้อผิดพลาด:", error);
                    this.$swal({
                      icon: "error",
                      title: this.$t('errorTitle'),
                      text: error.message || "ไม่สามารถทำรายการได้ในขณะนี้",
                    });
                  });
              }
            } else {
              // แจ้งเตือนหากยอดเงินไม่พอ
              this.$swal({
                icon: "warning",
                title: this.$t('errorTitle'),
                text: data.message || "ยอดเงินไม่เพียงพอ",
              });
            }
          })
          .catch(error => {
            console.error("เกิดข้อผิดพลาดในการตรวจสอบยอดเงิน:", error);
            this.$swal({
              icon: "error",
              title: this.$t('errorTitle'),
              text: error.message || "ไม่สามารถตรวจสอบยอดเงินได้ในขณะนี้",
            });
          });
      } else if (this.showImageUpload && this.getSelectedItem && !this.getSelectedItem.length) {
        this.$swal({
          title: this.$t('gameSingle.submitOrder'),
          text: this.$t('gameSingle.warningText2'),
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: this.$t('close'),
          confirmButtonText: this.$t('gameSingle.confirmButton'),
          customClass: {
            confirmButton: "btn bg-danger",
            cancelButton: "btn bg-dark",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("createOrder", this.order)
              .then(response => {
                if (response.success) {
                  // ถ้าการเติมเครดิตสำเร็จ, ย้ายไปหน้า check-order
                  this.$router.push('/member/order');
                } else {
                  throw new Error(response.message || "ไม่สามารถทำรายการได้ในขณะนี้");
                }
              })
              .catch(error => {
                console.error("เกิดข้อผิดพลาด:", error);
                this.$swal({
                  icon: "error",
                  title: this.$t('errorTitle'),
                  text: error.message || "ไม่สามารถทำรายการได้ในขณะนี้",
                });
              });
          } else if (
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal.dismiss;
          }
        });
      } else {
        this.$swal({
          icon: "warning",
          title: this.$t('gameSingle.validPackage'),
        });
      }
    },
    updateServerName(event) {
      const selectedValue = event.target.value;
      const selectedServer = this.game[0].gameservers.find(server => server.value === selectedValue);
      if (selectedServer) {
        this.order.server_name = selectedServer.name;
      }
    },
     updateOrderField(fieldName, value) {
      this.order_printout[fieldName] = value;
      // อัปเดต this.order.uid ด้วยถ้า fieldName คือ 'uid'
      if (fieldName.toLowerCase() === 'uid') {
        this.order.uid = value;
      }
    },
    downloadQRCode() {
      // เพิ่มคลาสอนิเมชั่นเมื่อเริ่มการดาวน์โหลด
      this.animationClass = true;

      // ใช้ DOMParser เพื่อแปลง HTML string เป็น DOM node
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.qrCodeImage, 'image/svg+xml');
      const svgBlob = new Blob([new XMLSerializer().serializeToString(doc.documentElement)], { type: 'image/svg+xml' });
      this.downloadUrl = URL.createObjectURL(svgBlob);

      // คลิกลิงก์เพื่อดาวน์โหลด
      const link = this.$refs.downloadLink;
      link.href = this.downloadUrl;
      link.click();

      // รีเซ็ต download URL
      URL.revokeObjectURL(this.downloadUrl);

      // ลบคลาสอนิเมชั่นหลังจากการดาวน์โหลดเสร็จ
      setTimeout(() => {
        this.animationClass = false;
      }, 1000); // ใช้เวลาตามที่ต้องการให้อนิเมชั่นเสร็จสิ้น
    },
    async genGenQrCode(totalPrice){
      if (this.myWallet < totalPrice) {
        totalPrice = totalPrice - this.myWallet
      } else {
        totalPrice = totalPrice
      }
      // เรียกใช้ genQrCode และรอให้ทำงานเสร็จ
      await this.$store.dispatch("genQrCode", {
        amount: totalPrice,
      });

      // รับค่าของ QR code
      const qrCodeSvg = this.$store.getters.getQrCode;
      this.qrCodeImage = qrCodeSvg;
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 991;
    },
    selectPaymentMethod(payment, method) {
      this.selectedPaymentMethod = method;

      // ทำการโหลดข้อมูลที่เกี่ยวข้องกับวิธีการชำระเงินที่เลือก
      if (method === "bank") {
        // โหลดข้อมูลธนาคาร
        this.dataBank = payment[0];
        this.topupData.bank_id = payment[0]._id;
        this.dataWallet = null
      } else if (method === "wallet") {
        // โหลดข้อมูลวอลเล็ต
        this.dataWallet = payment[1];
        this.topupData.bank_id = payment[1]._id;
        this.dataBank = null
      }
    },
    async downloadQRCodeTrue() {
      // ตรวจสอบว่า QrCodeTrue ถูกต้อง
      if (this.QrCodeTrue) {
        // กำหนด URL สำหรับดาวน์โหลด
        this.downloadUrlTrue = this.QrCodeTrue;

        // ตั้งชื่อไฟล์ที่ต้องการดาวน์โหลด
        this.$refs.downloadLinkTrue.download = 'qrcode.jpg';

        // รอให้ URL ถูกกำหนดอย่างสมบูรณ์ก่อนคลิกที่ลิงก์
        await new Promise(resolve => setTimeout(resolve, 100));

        // คลิกที่ลิงก์เพื่อเริ่มการดาวน์โหลด
        this.$refs.downloadLinkTrue.click();
      } else {
        console.error('QR Code URL is not set.');
      }
    },
    selectCheckbox(checkbox) {
      if (checkbox === 'packageSelection') {
        this.showPackageSelection = !this.showPackageSelection;
        if (this.showPackageSelection) {
          this.showImageUpload = false;
        }
      } else if (checkbox === 'imageUpload') {
        this.showImageUpload = !this.showImageUpload;
        if (this.showImageUpload) {
          this.showPackageSelection = false;
        }
      }
    },
    async handleFiles(event) {
      const files = event.target.files;
      const currentUploadCount = this.uploadedImages.length;

      if (currentUploadCount + files.length > 3) {
        this.$swal({
          icon: "error",
          title: this.$t('coinTopup.slipTitleError'),
          text: this.$t('gameSingle.rolesImg'),
          type: 'warning',
        });
        return;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // ตรวจสอบขนาดไฟล์ไม่เกิน 2MB
        if (file.size > 1.2 * 1024 * 1024) {
          this.$swal({
            icon: "error",
            title: this.$t('coinTopup.slipTitleError'),
            text: this.$t('coinTopup.slipMessageError'),
            type: 'warning',
          });
          continue; // ข้ามไฟล์นี้ไป
        }
        
        if (file.type.startsWith('image/')) {
          try {
            const formData = new FormData();
            formData.append('file', file);

            // ส่งไฟล์ไปยัง API ที่คุณทำไว้
            const response = await axios.post(`${this.API_URL}/file/upload`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });

            const file_name = response.data.datas.file_name;
            const imageUrl  = `https://termgame-api.goldenbug.co/files/${file_name}`;
            this.uploadedImages.push(imageUrl);
            this.attach_img_url.push(imageUrl);

          } catch (error) {
            console.error('Error uploading image:', error);
            this.$swal({
              icon: "error",
              title: this.$t('coinTopup.slipTitleError'),
              text: this.$t('gameSingle.messageErrorImg'),
              type: 'error',
            });
          }
        }
      }
    },
    removeImage(index) {
      this.uploadedImages.splice(index, 1);
      this.attach_img_url.splice(index, 1); // ลบ URL ของรูปภาพออกจาก attach_img_url
    },
    uploadImages() {
      // ลอจิกสำหรับการอัพโหลดรูปภาพไปยังเซิร์ฟเวอร์
      console.log('อัพโหลดรูปภาพ:', this.uploadedImages);
    },
    handlePack(typePack) {
      typePack.quantity = 1
      if (typePack.cost == 0 && typePack.price == 0) {
        this.showImageUpload = true
        this.prepareData = {
          product_name: typePack.name,
          product_price: this.getMemberProfile && this.marginTypeInPage > 0 ? typePack.price * (1 - this.marginTypeInPage / 100)  : typePack.price,
          product_id: typePack._id,
          product_total: parseInt(typePack.quantity),
          type_topup: typePack.game_id.topup_auto
        }
        this.order.product_buy.push(this.prepareData)
      }
    },
    undoPack() {
      this.showImageUpload = false
    },
    async filterMarginType() {
      if (!this.game) {
        console.error('this.game ยังไม่มีค่า');
        return;
      }
      const isAuto = this.game[0].topup_auto;
      const product_type = this.game[0].product_type;
      if (isAuto && product_type == 1){
        this.marginTypeInPage = this.getMemberProfile.margin_topup_auto
      } 
      if (!isAuto && product_type == 1) {
        this.marginTypeInPage = this.getMemberProfile.margin_id_pass
      }
      if (isAuto && product_type == 2){
        this.marginTypeInPage = this.getMemberProfile.margin_cash_card
      } 
    },
  },
  watch: {
    getSelectedItem: {
      handler(newValue, oldValue) {
        this.order.recived_bank_id = '';
        const removedItems = this.order.product_buy.filter(oldItem => !newValue.some(newItem => newItem._id === oldItem.product_id));
        if (removedItems.length > 0) {
          const index = this.order.product_buy.indexOf(removedItems[0]);
          this.destroyDropzone(index);
          // this.removeProduct(removedItems[0].product_id);
        }
      },
      deep: true
    },
    attach_img_url : {
      handler(newValue, oldValue) {
      },
      deep: true
    },
  }
};
</script>

<style scoped lang="scss">

.input-group > select {
  color: #fff;
  border: 1px solid #484847 !important;
  background: transparent;
  padding: 0.85rem;
  padding-left: 35px;
  border-top-left-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  
  option {
    color: #000;
  }
}

.sticky-top {
  position: sticky;
  position: -webkit-sticky;
  position: sticky;
  top: 112px;
  z-index: 1;
}

.cart-item {
  margin-bottom: 15px;
  padding-bottom: 15px;
  align-items: center;

  .pic {
    flex: 0 0 70px;
    margin-right: 20px;
    img {
      max-width: 60px;
    }
  }

}

.bank-logo {
  flex: 0 0 80px;
  margin-right: 20px;
  img {
    max-width: 80px;  
  }
}
    // .container {
    //     max-width: 960px;
    // }
    .shadow-blur {
        box-shadow: inset 0 0px 1px 1px rgb(84 84 84 / 90%), 0 20px 27px 0 rgb(82 82 82 / 5%) !important;
    }
    .-item {
        border-color: #FF4E00;
        display: flex;
        vertical-align: middle;
        justify-content: center;
        padding: 0 20px;
        position: relative;
        box-shadow: none;
        transition: .3s color ease-in-out, .3s background-color ease-in-out;
        span {
          top: -8px;
          left: 15px;
          right: 15px;
        }
        img {
          object-fit: cover;
        }
        h4 {
          color: #FF4E00;
          transition: .3s color ease-in-out;
        }
        &.item-active {
            // box-shadow: 0px 0px 10px 2px #FF4E00;
            background-color: #FF4E00;
            backdrop-filter: blur(10px);
            h4 {
              color: #fff;
            }
            color: #fff !important
        }
    }
    #slip::-webkit-file-upload-button {
      visibility: hidden;
    }
    #slip::before {
      content: attr(data-placeholder);
      display: inline-block;
      background: linear-gradient(top, #f9f9f9, #e3e3e3);
      border: 1px solid #999;
      border-radius: 3px;
      padding: 5px 8px;
      outline: none;
      white-space: nowrap;
      -webkit-user-select: none;
      cursor: pointer;
      font-weight: 700;
      font-size: 10pt;
    }
    #slip:hover::before {
      border-color: black;
    }
    #slip:active::before {
      background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    }

    .nav {
      &.nav-pills {
        .nav-link {
          &:not(.active) {
            color: #f9f9f9;
            &:hover {
              color: #f9f9f9;
            }
          }
        }
      }
    }

    .border-left {
      ::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: #f9f9f9;
      }
    }

    .game-detail {
      position: relative;
      overflow: hidden;
      max-height: calc(1.5rem * 8);
      transition: .3s max-height ease-in-out;
      * {
        line-height: 1.5rem;
        // margin-bottom: 1.5rem;
      }
      &.active {
        max-height: 100%;
      }
    }
    .minimize {
      .show-btn {
        visibility: visible;
      }
      .hidden-btn {
        visibility: hidden;
      }
    }
    .mask {
      top: -1px;
    }

    .modal {
      z-index: 99999999;
    }

    .modal-header {
      border: none;
    }
    .modal-dialog {
      max-width: 750px !important;
    }

    .price-tag {
      width: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center ;
    }

    .rounded-gb {
      border-radius: 10px 0px 0px 0px !important;
    }
    .mobile-display{
      display: none;
    }
    .special-con{
      background-color: rgb(29, 29, 29);
      border-radius: 20px;
      padding: 20px;
    }
    .card-game .savings{
      border-radius: 0 0 1rem 0 !important;
      line-height : 1.7 !important;
    }
    .special-con {
      position: relative;
      overflow: hidden;
      background: linear-gradient(45deg, #333, #444);
      border-radius: 10px;
      padding: 20px;
      transition: all 0.3s ease;
    }
    
    .special-con:hover {
      transform: scale(1.05);
      box-shadow: 0 0 20px rgba(255, 215, 0, 0.5);
    }
    
    .shiny-effect::before {
      content: '';
      position: absolute;
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
      background: linear-gradient(
        to bottom right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      transform: rotate(45deg);
      animation: shine 1.5s infinite linear; /* ปรับเวลาจาก 3s เป็น 1.5s และเพิ่ม linear */
    }

    .card-header {
      background: linear-gradient(60deg, #d40778, #610248);
    }
    
    .card-header h4 {
      text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
    }
    
    .fb-comments, .fb-comments iframe {
      width: 100% !important;
    }
    
    .fb-comments {
      border-radius: 0.5rem;
      overflow: hidden;
    }

    .discount{
      color: rgba(150,150,150);
      font-size: 0.6rem;
      opacity: 0.6;
      background-color: transparent;
      padding-top: 10px;
    }
    .discount-hover{
      color: rgb(255, 255, 255);
      font-size: 0.8rem;
      background-color: transparent;
      padding-top: 10px;
    }
    .savings{
      background-color: greenyellow;
      color: black;
    }
    .text-desc{
      font-size: 0.85rem !important;
      color: white;
      text-align: center;
      overflow: visible;
      max-height: 70px;
    }
    .info{
      width: 100%;
    }
    .product-title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      width: 100%;
    }
    .price{
      font-size: 1rem;
    }

    .card-box {
      transition: all 0.3s ease;
      overflow: hidden;
      border: none;
      background-color: #2c2c2c !important;
      cursor: pointer;
    }
    
    .card-box:hover {
      transform: translateY(-5px);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    }
    
    .card-gradient {
      background: linear-gradient(
        to bottom right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      height: 100%;
      padding: 1rem;
      border-radius: 10px;
    }
    
    .content-con{
      background-color: red;
      height: 100%;
      text-align: start;
      
    }
    .card-title {
      font-weight: bold;
      margin-bottom: 0.5rem;
      color: #fff;
      font-size: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    
    .card-text {
      font-size: 0.9rem;
      color: rgba(255, 255, 255, 0.8);
    }
    
    .card-box img {
      border-radius: 8px;
      transition: transform 0.3s ease;
    }
    
    .card-box:hover img {
      transform: scale(1.05);
    }
    
    .shadow {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  
    .carousel__prev,
    .carousel__next {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 24px;
      cursor: pointer;
      transition: background-color 0.3s;
    }
  
    .carousel__prev:hover,
    .carousel__next:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  
    .carousel__prev {
      left: -24px;
    }
  
    .carousel__next {
      right: -24px;
    }
  
    .slide-fade-enter-active,
    .slide-fade-leave-active {
      transition: all 0.5s ease;
    }
    .slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
      transform: translateY(-20px);
      opacity: 0;
    }
    .game-image {
      width: 60px !important;
      height: 60px !important;
    }
    .game-image-id-pass{
      width: 150px;
      height: 150px; 
    }

    .payment-method-card {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      padding: 15px;
      text-align: center;
      cursor: pointer;
      position: relative;
      transition: all 0.3s ease;
    }
    
    .payment-method-card:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
    
    .payment-method-card.selected {
      background-color: rgba(224, 11, 96, 0.5);
      border: 2px solid #e00b60;
    }
    
    .payment-method-card i {
      font-size: 24px;
      color: white;
      margin-bottom: 5px;
    }
    
    .payment-method-card span {
      display: block;
      color: white;
    }
    
    .check-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      color: #e00b60;
    }
    .quantity-input {
      height: 38px;
    }
    
    .btn-quantity {
      width: 38px;
      height: 38px;
      border: 0;
      color: #495057;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background-color 0.2s;
      font-size: 24px;
      color: white;
      font-weight: bold;
    }
    
    .btn-quantity:hover {
      background-color: #e9ecef;
    }
    
    .quantity-value {
      width: 38px;
      height: 38px;
      text-align: center;
      border-top: none !important;
      border-bottom: none !important;
      border-left: none !important;
      border-right: none !important;
      font-size: 16px;
      padding: 0;
      border-radius: 0;
      background-color: transparent;
      color: white;
    }
    .list-action{
      cursor: pointer;
      transition: all .3s ease-in;
      background-color: rgb(31, 31, 31);
    }
    .list-action:hover{
      background-color: rgb(48, 48, 48);
    }

    .image-upload-container {
      display: flex;
      justify-content: start;
      align-items: center;
      background-color: black;
      margin-left: 0;
    }
    
    .image-upload-card {
      background-color: rgb(29, 29, 29);
      border-radius: 12px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      padding: 1rem 2rem 2rem 2rem;
      width: 100%;
    }
    
    .upload-title {
      color: white;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
      text-align: center;
    }
    
    .upload-area {
      border: 2px dashed #e00b60;
      border-radius: 8px;
      padding: 2rem;
      text-align: center;
      transition: background-color 0.3s ease;
    }
    
    .upload-area:hover {
      background-color: rgb(54, 54, 54);
    }
    
    .upload-label {
      color: #e00b60;
      cursor: pointer;
      display: block;
    }
    
    .upload-label i {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
    
    .upload-label span {
      display: block;
    }
    
    #fileElem {
      display: none;
    }
    
    .image-preview {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 1rem;
    }
    
    .upload-button {
      background-color: #e00b60;
      border: none;
      border-radius: 4px;
      color: white;
      cursor: pointer;
      font-size: 1rem;
      margin-top: 1rem;
      padding: 0.5rem 1rem;
      transition: background-color 0.3s ease;
      width: 100%;
    }
    
    .upload-button:hover {
      background-color: #e00b60;
    }

    .uploaded-images {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    
    .uploaded-image {
      width: 150px;
      height: 150px;
      object-fit: cover;
      border-radius: 8px;
    }
    textarea.form-control {
      border: 1px solid #ced4da;
      border-radius: 0.375rem;
      padding: 0.75rem; 
      font-size: 1rem; 
      color: white;
      background-color: rgb(29, 29, 29); 
      resize: none; 
    }
    
    textarea.form-control::placeholder {
      color: #6c757d;
    }

    .uploaded-images .image-container {
      position: relative;
      display: inline-block;
      margin: 10px;
      cursor: pointer;

    }

    .uploaded-images .delete-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgb(255, 0, 0);
      color: white;
      border: none;
      border-radius: 50%;
      padding: 5px 13px;
      cursor: pointer;
      opacity: 0.7;
      transition: opacity 0.3s ease;
    }
    
    .uploaded-images .image-container:hover .delete-button {
      opacity: 1;
    }

    .undo-text{
      background-color: transparent;
      transition: .3s all ease-in-out;
      padding: 10px;
      border-radius: 20px;
      cursor: pointer;
    }
    .undo-text:hover{
      background-color: #373737;
    }

    .fade-enter-active, .fade-leave-active {
      transition: opacity 0.5s ease, transform 0.5s ease;
    }
    
    .fade-enter, .fade-leave-to {
      opacity: 0;
      transform: translateX(-30px); /* fade เข้าเคลื่อนที่จากซ้าย */
    }
    
    .fade-leave, .fade-enter-to {
      opacity: 1;
      transform: translateX(0); /* fade ออกค่อยๆหายไปที่ตำแหน่งปัจจุบัน */
    }
    .greyscale {
      filter: grayscale(100%) !important;
    }
    .is-invalid {
      border-color: red !important;
    }
    .form-control.valid-input{
      border-color: rgb(77, 255, 0) !important;
    }
    .form-control.valid-input:focus{
      border-color: rgb(77, 255, 0) !important;
      box-shadow : 0 0 5px 2px rgb(77, 255, 0) !important
    }
    .valid-icon {
      color: black;
      margin-left: 8px;
      font-size: 18px;
      background-color: rgb(77, 255, 0);
      padding: 5px;
      border-radius: 50%;
    }
    @keyframes shine {
      0% {
        transform: translateX(-100%) rotate(45deg);
      }
      100% {
        transform: translateX(100%) rotate(45deg);
      }
    }

    .animate-download {
      animation: clickAnimation 0.5s ease-out;
    }
    
    /* Animation keyframes */
    @keyframes clickAnimation {
      0% {
        transform: scale(1);
        opacity: 1;
      }
      50% {
        transform: scale(1.05);
        opacity: 0.8;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }
    @media screen and (max-width: 768px) {
      .mobile-display{
        display: block;
      }
      .desktop-display{
        display: none !important;
      }
      .text-mobile{
        font-size: 12px !important;
      }
      .discount-mobile{
        font-size: 0.7rem !important;
      }
      .price-mobile{
        font-size: 1rem !important;
      }

    }
</style>